import { useNavigate } from "react-router-dom";

import { useState, useRef } from "react";

import { Button, Switch, Checkbox, Grid, FormControlLabel, Typography, CardHeader } from "@mui/material";

import { useAlertContext } from "./AlertContext";
import { useLoading } from "./LoadingContext";
import Map from "./Map";
import { coordinatesDistance } from "../utils/coordinatesDistance";

const Distance = ({ caminos }) => {
  const alertContext = useAlertContext();
  const { setLoading } = useLoading();
  const [state, setState] = useState("new");
  const [kmlSource, setKmlSource] = useState(caminos.map((object,index) => ({id: index+1, name: object.name, kmlUri: `${process.env.REACT_APP_SPACES_CDN_ENDPOINT}/kmls/${encodeURIComponent(object.name)}.kml`})));
  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState(null);
  const [distance, setDistance] = useState(0);
  const [zoom, setZoom] = useState(5.8);
  const [center, setCenter] = useState({lat: 40, lng: -5});
  const [start, setStart] = useState({lat: 0, lng: 0});
  const [end, setEnd] = useState({lat: 0, lng: 0});
  const [mapKey, setMapKey] = useState(1);
  const MPP = 156543;
  const snap = useRef(true);
  const [snapLabel, setSnapLabel] = useState(true);
  const [polylines, setPolylines] = useState({to: [], from: []});


  function adjustZoom(coordinates) {
    const minLat = Math.min(...coordinates.map(item => item.lat));
    const maxLat = Math.max(...coordinates.map(item => item.lat));
    const minLong = Math.min(...coordinates.map(item => item.long));
    const maxLong = Math.max(...coordinates.map(item => item.long));

    const maxX = coordinatesDistance(minLat, minLong, minLat, maxLong);
    const maxY = coordinatesDistance(minLat, minLong, maxLat, minLong);

    const zoomX =  Math.log((MPP * window.innerWidth * (window.innerWidth < 600 ? .60 : .25)) / maxX) / Math.log(2);
    const zoomY =  Math.log((MPP * window.innerHeight * .4) / maxY) / Math.log(2);

    setZoom(Math.min(zoomX, zoomY));  
  };

  const getNearestCoordinate = (position) => {
    let nearestCoordinates = {position: {}, distance: 1000000000};
    for (const coordinate of coordinates) {
      const distance = coordinatesDistance(coordinate.lat, coordinate.long, position.lat, position.lng);
      if (distance < nearestCoordinates.distance) {
        nearestCoordinates = {position: {lat: coordinate.lat, lng: coordinate.long}, distance: distance}
      }      
    }
    return nearestCoordinates
  }

  const getDistanceToCamino = async (position, nearest) => {
    // get the route
    const routeResponse = await fetch(`/api/route?olat=${position.lat}&olong=${position.lng}&dlat=${nearest.lat}&dlong=${nearest.lng}&mode=WALK`);
    const route = await routeResponse.json()
    if (route) {
      const poly = route.coordinates.map(coord => ({lat: coord[0], lng: coord[1]}));
      return {distance: route.distance, poly: poly};
    }
    return {distance: 0, poly: []};
  }


  const getDistanceOnCamino = (start, end) => {    
    let distance = 0;    
    const startIndex = coordinates.findIndex(coordinate => coordinate.lat === start.lat && coordinate.long === start.lng);
    const endIndex = coordinates.findIndex(coordinate => coordinate.lat === end.lat && coordinate.long === end.lng);
    // adjust for possible reverse travel
    const adjustedStart = Math.min(startIndex, endIndex);
    const adjustedEnd = Math.max(endIndex, startIndex);
    for (let i = adjustedStart; i < adjustedEnd; i++) {
      distance += coordinatesDistance(coordinates[i].lat, coordinates[i].long, coordinates[i+1].lat, coordinates[i+1].long);
    }
    return distance;
  }

  const totalDistance = async (start, end) => {
    let distance = 0;
    let to = {};
    let from = {};
    let newStart = start;
    let newEnd = end;
    const startIndex = coordinates.findIndex(coordinate => coordinate.lat === start.lat && coordinate.long === start.lng);
    if (startIndex < 0) {
      newStart = getNearestCoordinate(start).position;
      to = await getDistanceToCamino(start, newStart);
      distance += to.distance;
    }
    const endIndex = coordinates.findIndex(coordinate => coordinate.lat === end.lat && coordinate.long === end.lng);
    if (endIndex < 0) {
      newEnd = getNearestCoordinate(end).position;
      from = await getDistanceToCamino(end, newEnd);
      distance += from.distance;
    }
    distance += getDistanceOnCamino(newStart, newEnd);
    setPolylines({to: to.poly, from: from.poly});
    return distance;
  }

  async function success(position) {
    // testing ---------------------------
    // const lat = 42.6036038
    // const lng = -5.5980337
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    //------------------------------------
    snap.current = false;
    setSnapLabel(false);
    await handleMapClick({ lat: lat, lng: lng});
  };

  async function error() {
    await alertContext.showConfirmation(
      {title: "Unable to retrieve your location. Sorry :(",
       cancelButton: "Continue",
      }
    );
    setLoading(false);    
    navigate(-1);
  } 

  async function getLocation() {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(async function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(success, error);
          } else if (result.state === "prompt") {
            setLoading(true)
            navigator.geolocation.getCurrentPosition(success, error);
          } else if (result.state === "denied") {
            await alertContext.showConfirmation(
              {title: "To use this funciton we need your current location but your permissions are turned off. Please check location permission for your device and browser and then give permission for our website.",
              cancelButton: "Continue",
              }
            );    
          }
        });
    } else {
      await alertContext.showConfirmation(
        {title: "To use this funciton we need your current location but your permissions are turned off. Please check location permission for your device and browser and then give permission for our website.",
          cancelButton: "Continue",
        }
      );    
    }  
  }


  const handleKmlClick = async (kml) => {
    setLoading(true);
    const fieldList = '["_id","name","center", "coordinates"]'
    const search = `?fields=${fieldList}`;
    const response = await fetch(`/api/caminos/${kml}${search}`);
    const camino = await response.json();
    setKmlSource([{id: camino.name, name: camino.name, kmlUri: `${process.env.REACT_APP_SPACES_CDN_ENDPOINT}/kmls/${encodeURIComponent(camino.name)}.kml`}]);
    setCoordinates(camino.coordinates);
    setCenter(camino.center);
    adjustZoom(camino.coordinates);
    setState("start");
    setMapKey(Math.random());
    setLoading(false);
  }

  const handleMapClick = async (clickedLocation) => {
    let position = clickedLocation;
    if (snap.current) {
      const returnedPosition = getNearestCoordinate(position);
      position = returnedPosition.position;
    }
    if (state !== "end") {
      if (end.lat === 0) {
        setZoom(9);
        setCenter(position);
        setState("end");
      } else {
        adjustZoom([{lat: end.lat, long: end.lng}, {lat: position.lat, long: position.lng}]);
        setCenter({lat: (end.lat + position.lat) / 2, lng: (end.lng + position.lng) / 2});
        setDistance(await totalDistance(position, end))
      }
      setStart(position);
      setMapKey(Math.random());
    } else {
      if (start.lat === 0) {
        setZoom(9);
        setCenter(position);
        setState("start");
      } else {
        adjustZoom([{lat: start.lat, long: start.lng}, {lat: position.lat, long: position.lng}]);
        setCenter({lat: (start.lat + position.lat) / 2, lng: (start.lng + position.lng) / 2});
        setDistance(await totalDistance(start, position))
      }
      setEnd(position);
      setMapKey(Math.random());
    }
  }

  const handleReset = () => {
    setKmlSource(caminos.map((object,index) => ({id: index+1, name: object.name, kmlUri: `${process.env.REACT_APP_SPACES_CDN_ENDPOINT}/kmls/${encodeURIComponent(object.name)}.kml`})));
    setZoom(5.8);
    setCenter({lat: 40, lng: -5});
    setStart({lat: 0, lng: 0});
    setEnd({lat: 0, lng: 0});
    setState("new");
    snap.current = true;
    setSnapLabel(true);
    setDistance(0);
    setPolylines({to: [], from: []});
    setMapKey(Math.random());
  };

  const handleToggle = () => {
    if (state === "start") {
      setState("end");
      setMapKey(Math.random());
    } else if (state === "end") {
      setState("start")
      setMapKey(Math.random());
    }
  }

  const handleSnapToggle = () => {
    snap.current = !snap.current;
    setSnapLabel(!snapLabel);
  }

  return (
    <>        
      <CardHeader 
        title="Camino Distance Calculator"
        action={
          <Button 
            sx={{height: 30}} 
            variant="contained" 
            onClick={handleReset}
          >
            Reset
          </Button>
        }
      />
      <Typography fontSize={16} align="center">
        {state !== "new" ?
          <>
          <span>Click location on map or </span>
          <Button onClick={getLocation}>Use Current Location</Button>
          </>
        : "Click a Camino to Start"
        }
      </Typography>
      { state !== "new" ?
        <Grid container item mb={2} alignItems="center" justifyContent="center">
          <Typography padding={1} fontSize={16} backgroundColor="grey.200">
            {"Distance: " + (distance / 1000).toFixed(1) + " km"}
          </Typography>
          <FormControlLabel
            sx={{ml: 5, mr: 5, color: state === "start" ? "green" : "red"}}
            value="bottom"
            control={
              <Switch
                checked={state === "end"}
                onChange={handleToggle}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={state.toUpperCase()}
            labelPlacement="bottom"
          />
          <FormControlLabel 
            sx={{width: {xs: 100, md: 150}}} 
            control={
              <Checkbox 
                checked={snapLabel} 
                onChange={handleSnapToggle}  
              />} 
            label="Snap to Camino?"
          />          
        </Grid>
      : null }
      <Map
        key={mapKey} 
        center={center}
        zoom={zoom}
        style={{width: "100%", height: "60vh" }}
        kmlSource={kmlSource}
        route={[polylines.to, polylines.from]}
        handleKmlClick={state ==="new" ? handleKmlClick : null}
        handleMapClick={handleMapClick}
        noControls={state === "new" ? true : false}
        getClick={state !== "new" ? true : false}
        startMarker={start}
        endMarker={end}
      />
    </>
  )
};

export default Distance;
